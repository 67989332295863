<template>
  <div class="swiper_box">
    <BannerItemV2 v-if="data.length === 0" v-bind="defaultBanner"> </BannerItemV2>
    <el-carousel v-else trigger="click" height="255px" :interval="interval">
      <el-carousel-item
        v-for="(item, index) in data"
        :key="index"
        @click.native="clickBannerUrl(item.bannerUrl)"
        :class="item.bgClass"
      >
        <BannerItemV2 v-bind="item" :lang="imgLang"> </BannerItemV2>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import BannerItemV2 from '@/components/banner/item/v2/BannerItem.vue';
import { banners, defaultBanner } from '@/constants/banners';

export default {
  components: {
    BannerItemV2
  },
  data() {
    return {
      interval: 10000,
      windowWidth: window.innerWidth
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  computed: {
    campaignEligibility() {
      return this.$store.state.promotion.eligibleCampaigns;
    },
    imgLang() {
      return this.$store.state.common.lang;
    },
    data() {
      const result = [];
      const campaignType = this.campaignEligibility.map(m => {
        return m.campaignType;
      });
      banners.forEach(key => {
        if (!key.promotionType || campaignType.includes(key.promotionType)) {
          if (this.isDepositBonus(key.promotionType)) {
            if (this.getTypeId(key.promotionType) !== key.promotionId) return;
          }
          if (key.restrictRegulator?.includes(this.regulator)) return;
          if (this.isPropTrading(key.promotionName) && !this.$store.state.propTrading.eligible) return;
          result.push(key);
        }
      });
      return result;
    },
    defaultBanner() {
      return defaultBanner;
    }
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    clickBannerUrl(url) {
      if (!url) return;
      if (url.startsWith('http')) {
        window.open(url, '_blank');
        return;
      }
      this.$router.push({ path: url });
    },
    isDepositBonus(type) {
      return type === 'DEPOSIT_BONUS_2' ? true : false;
    },
    isPropTrading(type) {
      return type?.toUpperCase?.() === 'propTrade'.toUpperCase();
    },
    getTypeId(type) {
      return this.campaignEligibility.find(el => el.campaignType === type).id;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/layout/banner.scss';
</style>
