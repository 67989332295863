<template>
  <div class="notification-wrapper" :class="{ mobile: isMobile }" v-show="loginStatus && showNotification">
    <div class="noticeDisable">
      <span @click.stop.prevent="disableNotification" data-testid="noticeDisable"
        ><img src="@/assets/images/notification.png" alt
      /></span>
    </div>
    <div class="notice" @click.stop.prevent="handleClickNotification" data-testid="notice">
      <span class="notification">{{ enabledNoticeTitle }}</span>
    </div>
    <template slot="components">
      <Notice :notificationVisible.sync="notificationVisible" :notifications="notifications"></Notice>
    </template>
  </div>
</template>

<script>
import { apiGetNotifications } from '@/resource';
import Notice from '@/components/notification/Notice';
import { HTMLdecode } from '@/util/utils.js';
export default {
  name: 'headerNotification',
  components: { Notice },
  props: {
    sliderBarWidth: Number,
    headerWidth: Number,
    isRestrictSidebar: Boolean,
    isMobile: Boolean
  },
  data() {
    return {
      notificationVisible: false,
      showNotification: true,
      notifications: [
        {
          subject: '',
          status: 1,
          country: 'All',
          displayType: '',
          notificationDocuments: []
        }
      ]
    };
  },
  computed: {
    countryCode() {
      return parseInt(this.$store.state.common.countryCode);
    },
    lang() {
      return this.$store.state.common.lang;
    },
    loginStatus() {
      return this.$store.state.common.loginStatus;
    },
    autoNotifyStatus() {
      return this.$store.state.common.autoNotifyStatus;
    },
    enabledNoticeTitle() {
      if (this.notifications.length < 1 || this.notifications[0].notificationDocuments.length < 1) {
        return '';
      }

      let currentLangNotiDoc = this.notifications[0].notificationDocuments.find(doc => doc.language_code === this.lang);
      if (!currentLangNotiDoc || currentLangNotiDoc.is_del === 1) {
        currentLangNotiDoc = this.notifications[0].notificationDocuments.find(doc => doc.language_code === 'en_US');
      }
      return HTMLdecode(currentLangNotiDoc.subject);
    },
    isInhibitSidebar() {
      return this.isRestrictSidebar;
    }
  },
  watch: {
    loginStatus(loginStatus) {
      if (loginStatus) this.getNotification(false);
    }
  },
  mounted() {
    if (this.loginStatus) this.getNotification(this.autoNotifyStatus);
  },
  methods: {
    handleDecoding(str) {
      return str
        .replace(/amp;/gi, '')
        .replace(/&lt;/gi, '<')
        .replace(/&gt;/gi, '>')
        .replace(/&quot;/gi, '"')
        .replace(/&nbsp;/gi, ' ');
    },
    disableNotification() {
      this.showNotification = false;
    },
    handleClickNotification() {
      this.notificationVisible = true;
    },
    getNotification(isShow) {
      apiGetNotifications({ country: this.countryCode })
        .then(res => {
          if (res.data.data.length === 0) {
            this.disableNotification();
          } else {
            this.notifications = res.data.data
              .slice()
              .sort((a, b) => a.priority - b.priority)
              .map(e => {
                e.notificationDocuments.map(doc => this.handleDecoding(doc.document));
                return e;
              });
            if (this.notifications[0].announcementDisplayType === 0 && isShow) {
              this.$store.commit('common/setAutoNotifyStatus', false);
              this.notificationVisible = true;
            }
          }
        })
        .catch(err => {
          console.log(err);
          this.showNotification = false;
        });
    },
    calWidth(width, val1, val2) {
      return this.headerWidth >= width ? this.headerWidth + val1 : this.headerWidth + val2;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/notification/headerNotification.scss';
</style>
