// Standardize with BE (BannerDisplayPageEnum filter by [1])
export const popupBannerDisplayPageEnum = Object.freeze({ home: 1, download: 2, deposit: 4 })

// popupBannerDisplayPageEnum?
export const getPopupBannerDisplayPageEnumFromRoutePath = routePath => {
  switch (routePath) {
    case '/home':
    case '/register':
      return popupBannerDisplayPageEnum.home
    case '/downloads':
      return popupBannerDisplayPageEnum.download
    case '/depositFunds':
      return popupBannerDisplayPageEnum.deposit
    default:
      return null
  }
}
