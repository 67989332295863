import moment from 'moment'

function minusTime(startTime, endTime) {
  return new Date(endTime) - new Date(startTime)
}

function formatDateTime(timestamp, format) {
  const momentDateTime = moment(timestamp)
  const formattedDateTime = momentDateTime.format(format)
  return formattedDateTime
}

export { minusTime, formatDateTime }
