<template>
  <div class="bannerContainer">
    <img src="@/assets/images/download/close.png" class="close-icon" @click="handleClose" data-testid="closeImg" />

    <img
      v-if="clickAction"
      :src="!isPC && bannerDetails.bannerMobileImage ? bannerDetails.bannerMobileImage : bannerImage"
      :class="`bannerImage pointer-cursor ${showClass} `"
      @click="clickAction"
    />
    <img
      v-else
      :src="!isPC && bannerDetails.bannerMobileImage ? bannerDetails.bannerMobileImage : bannerImage"
      :class="`bannerImage ${showClass}`"
    />
    <div v-if="isShowSnoozeCheckbox" class="bannerSnoozeContainer absolute bottom-[12px] right-4">
      <el-checkbox v-model="checked">
        <span>{{ $t('popupBanner.doNotShowThisAgain') }}</span>
      </el-checkbox>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopupContent',
  props: {
    isPC: { type: Boolean, default: false },
    bannerDetails: { type: Object, default: null },
    bannerImage: { type: String, default: '' },
    callToActionLink: { type: String, default: '' },
    handleClose: { type: Function, required: true },
    clickAction: { type: [Function, null], default: null },
    addRemoveSnoozeBanner: { type: [Function, null], default: null }
  },
  data() {
    return {
      checked: false
    };
  },
  computed: {
    isShowSnoozeCheckbox() {
      return this.addRemoveSnoozeBanner !== null;
    },
    showClass() {
      return !this.isPC ? 'mobile' : null;
    }
  },
  watch: {
    checked(checked) {
      if (checked && this.addRemoveSnoozeBanner) {
        this.addRemoveSnoozeBanner('add');
      } else {
        this.addRemoveSnoozeBanner('remove');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/home/carouselBanner.scss';
</style>
