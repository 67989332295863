<template>
  <div v-if="serverTime" class="timer-container">
    {{ formatTimeData(serverTime.hour()) }} : {{ formatTimeData(serverTime.minute()) }} :
    {{ formatTimeData(serverTime.second()) }} ({{ gmt }})
  </div>
</template>

<script>
import moment from 'moment';
import { apiGetServerTime } from '@/resource';
import { mapActions } from 'vuex';

export default {
  name: 'TimerCounter',
  data() {
    return {
      serverTime: null,
      gmt: null
    };
  },
  watch: {
    serverTime: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          setTimeout(() => {
            this.serverTime = moment(this.serverTime.add(1, 'seconds'));
          }, 1000);
        }
      }
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions('promotion', ['actionSetServerTime']),
    async init() {
      try {
        const res = await apiGetServerTime();
        if (res?.data?.code == 0 && res?.data?.data) {
          this.serverTime = moment(`${res.data?.data?.serverTime}`);
          this.gmt = res.data?.data?.offset.split(':')[0];

          // set server time
          this.actionSetServerTime(this.serverTime);
        }
      } catch (error) {}
    },
    formatTimeData(value) {
      const stringValue = value.toString();
      let res = stringValue.length && Number(stringValue) <= 9 ? stringValue.padStart(2, '0') : stringValue;
      return res.toString();
    }
  }
};
</script>

<style lang="scss" scoped>
.timer-container {
  font-weight: 400;
  font-size: 14px;
  color: $pug-white;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
