<template>
  <el-dialog
    :visible.sync="innerVisible"
    :class="additionalClass"
    v-bind="getBindProps"
    v-on="getOnEvents"
    @close="close"
  >
    <template slot="title">
      <slot name="title"></slot>
    </template>
    <div class="dialog_body">
      <slot></slot>
    </div>
    <template slot="footer">
      <slot name="footer"></slot>
    </template>
  </el-dialog>
</template>

<script>
// New Dialog
export default {
  name: 'NDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    closeFlag: {
      type: Boolean,
      default: false
    },
    // 兼容 home/vDialog.vue
    enableCloseOnBackground: {
      type: Boolean,
      default: true
    },
    // 兼容 home/vDialog.vue
    additionalClass: String
  },
  computed: {
    getBindProps() {
      return {
        // 设置默认值
        'append-to-body': true,
        width: '600px',
        ...this.$attrs,
        'close-on-click-modal':
          typeof this.$attrs.closeOnClickModal === 'boolean'
            ? this.$attrs.closeOnClickModal
            : this.enableCloseOnBackground,
        'custom-class': this.getCustomClass
      };
    },
    getOnEvents() {
      return {
        ...this.$listeners
      };
    },
    getCustomClass() {
      let defaultClass = 'new-dialog-custom-class';
      // 如果有title或者slot的title，就加上has-header 给body加上margin-top
      if (this.$attrs.title || this.$slots.title) {
        defaultClass = `${defaultClass} has-title`;
      }
      // 如果有slot的footer，就加上has-footer 给body加上margin-bottom
      if (this.$slots.footer) {
        defaultClass = `${defaultClass} has-footer`;
      }
      if (this.$attrs['custom-class']) {
        defaultClass = `${defaultClass} ${this.$attrs['custom-class']}`;
      }
      return defaultClass;
    },
    innerVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit('update:visible', val);
      }
    }
  },
  mounted() {},
  methods: {
    close() {
      if (this.closeFlag) {
        this.$emit('close');
      }
    }
  }
};
</script>

<style lang="scss">
.new-dialog-custom-class {
  padding: 40px 60px;
  border-radius: 16px;

  @include screen-mobile() {
    padding: 25px 30px;
  }

  .el-dialog__header {
    padding-top: 10px;

    .el-dialog__title {
      font-size: 20px;
      line-height: 32px;
      color: $blue;
    }
  }

  .el-dialog__body {
    padding: 0;
    font-size: 14px;
  }

  .el-dialog__footer {
    padding: 0;
  }

  &.has-title {
    .el-dialog__body {
      margin-top: 20px;
    }
  }

  &.has-footer {
    .el-dialog__body {
      margin-bottom: 20px;
    }
  }
}
</style>
