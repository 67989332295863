const {
  REFER_A_FRIEND_PATHNAME,
  DEPOSIT_BONUS_PATHNAME,
  FIRST_DEPOSIT_CASHBACK_PATHNAME,
  PROP_TRADING,
  EASTER,
  FUNDS_GROWTH_PROMO_PATHNAME,
} = require('@/constants/route')

export const banners = [
  {
    bgClass: 'bg-black',
    rightSideImg: {
      img: require('@/assets/images/banner/prop_trade/right.png'),
      imgSmall: require('@/assets/images/banner/prop_trade/right_sm.png'),
      breakpoint: 960,
    },
    title: 'propTrade.banner.title',
    bannerUrl: PROP_TRADING,
    promotionName: 'propTrade',
    openResize: true,
  },
  {
    bgClass: 'bg-future-image',
    rightSideImg: {
      img: require('@/assets/images/banner/first_deposit_cashback/right.png'),
    },
    leftSideImg: {
      img: require('@/assets/images/banner/first_deposit_cashback/left.png'),
    },
    title: 'promotion.firstDepositCashback.banner.title',
    bannerUrl: FIRST_DEPOSIT_CASHBACK_PATHNAME,
    promotionName: 'firstDepositCashback',
    promotionType: 'FIRST_TIME_DEPOSIT_BONUS_CASHBACK',
    promotionId: 40,
    openResize: true,
  },
  {
    bgClass: 'bg-future-blue',
    rightSideImg: {
      img: require('@/assets/images/banner/deposit_bonus/right.png'),
      imgSmall: require('@/assets/images/banner/deposit_bonus/small_right.png'),
      breakpoint: 576,
    },
    leftSideImg: {
      img: require('@/assets/images/banner/deposit_bonus/left.png'),
    },
    title: 'promotion.depositBonus.banner.title',
    bannerUrl: DEPOSIT_BONUS_PATHNAME,
    promotionName: 'depositBonus',
    promotionType: 'DEPOSIT_BONUS_2',
    promotionId: 6,
    openResize: true,
  },
  {
    bgClass: 'bg-gradient-blue',
    bgImg: require('@/assets/images/banner/refer_friend/bg.png'),
    rightSideImg: {
      img: require('@/assets/images/banner/refer_friend/right.png'),
    },
    bannerUrl: REFER_A_FRIEND_PATHNAME,
    promotionName: 'referFriend',
    promotionType: 'REFER_A_FRIEND_3',
    isExtraItem: true,
    openResize: true,
  },
]

export const defaultBanner = {
  promotionName: 'defaultBanner',
  title: 'promotion.defaultBanner.banner.title',
}
