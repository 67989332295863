<template>
  <el-container :key="reRender" class="wrapper">
    <el-aside
      v-show="!isRestrictSidebar"
      :width="!matches ? center : ''"
      v-resizeObserver="{
        openResize: true,
        handler: slideBarCallBack
      }"
    >
      <slideBar
        :isCollapse.sync="isCollapse"
        :matches="matches"
        :onOpen.sync="onOpen"
        @matchesClose="matchesClose"
      ></slideBar>
    </el-aside>

    <el-container
      class="is-vertical"
      v-resizeObserver="{
        openResize: true,
        handler: headerCallBack
      }"
    >
      <vHeader
        :matches="matches"
        :onOpen.sync="onOpen"
        :isCollapse.sync="isCollapse"
        :sliderBarWidth="sliderBarWidth"
        :headerWidth="headerWidth"
        :isRestrictSidebar="isRestrictSidebar"
        @openMenu="openMenu"
      ></vHeader>
      <el-main id="elMain">
        <Banner v-if="showBanner"></Banner>
        <transition>
          <router-view></router-view>
        </transition>
        <SessionDialog />
      </el-main>
    </el-container>
    <CarouselBanner v-if="showPopupBanner" />
  </el-container>
</template>

<script>
import vHeader from '@/components/template/header/Header';
import slideBar from '@/components/Menu';
import Banner from '@/components/banner/Banner';
import SessionDialog from '@/components/SessionDialog.vue';
import { apiSetlanguage } from '@/resource';
import CarouselBanner from '@/components/home/CarouselBanner.vue';

export default {
  name: 'Layout',
  components: { vHeader, slideBar, Banner, SessionDialog, CarouselBanner },
  data() {
    return {
      reRender: 0,
      center: '260px',
      matches: false,
      smallMatches: false,
      isCollapse: false,
      onOpen: true,
      showBanner: false,
      sliderBarWidth: 0,
      headerWidth: 0,
      showPopupBanner: false
    };
  },
  computed: {
    lang() {
      return this.$store.state.common.lang;
    },
    isRestrictSidebar() {
      return this.$route.meta.restrictSideBar || false;
    }
  },
  watch: {
    lang: {
      immediate: true,
      async handler(val) {
        this.$i18n.locale = val;
        if (this.$store.state.common.loginStatus) await this.setUserLanguagePreference(val);
        this.reRender++;
      }
    },
    reRender(reRender) {
      if (this.$route.meta.restrictReload) this.$router.push('/home');
    },
    onOpen(bool) {
      this.isCollapse = !bool;
    },
    isCollapse(bool) {
      if (!bool && this.onOpen) {
        this.center = '260px';
      } else if (!this.matches) {
        this.center = '64px';
      } else {
        this.center = '0';
      }
    },
    matches(bool) {
      if (bool) {
        this.onOpen = false;
        this.center = '0';
        this.isCollapse = false;
      } else {
        this.onOpen = true;
        this.center = '260px';
      }
    },
    smallMatches(bool) {
      if (bool) {
        this.onOpen = false;
      }
    },
    $route: {
      handler: function (route) {
        this.showBanner = route.meta.restrictBanner ? false : true;

        if (route.path == '/home' || route.path == '/downloads' || route.path == '/depositFunds') {
          this.showPopupBanner = true;
        } else {
          this.showPopupBanner = false;
        }

        this.$nextTick(() => {
          const elMain = document.getElementById('elMain');
          elMain.scrollTop = route.path.indexOf('deposit/') != -1 ? 250 : 0;
        });
      },
      immediate: true
    }
  },
  mounted() {
    const vm = this;
    vm.checkSize();
    $(window).resize(function () {
      vm.checkSize();
    });

    if (this.$route.path == '/home' || this.$route.path == '/downloads' || this.$route.path == '/depositFunds') {
      this.showPopupBanner = true;
    } else {
      this.showPopupBanner = false;
    }
    this.checkCampaignEligibility();
    this.$store.dispatch('propTrading/actionGetEligiblePropTrading');
  },
  methods: {
    checkSize() {
      let w = window.innerWidth;

      if (w <= 1023 && w >= 768) {
        this.smallMatches = window.matchMedia('(max-width: 1023px)').matches;
        this.matches = false;
      } else if (w < 768) {
        this.matches = window.matchMedia('(max-width: 768px)').matches;
        this.smallMatches = false;
      } else {
        this.matches = false;
        this.smallMatches = false;
      }
    },
    async setUserLanguagePreference(lang) {
      await apiSetlanguage({ language: lang });
    },
    openMenu() {
      this.isCollapse = !this.isCollapse;
      this.onOpen = !this.onOpen;
    },
    matchesClose() {
      this.onOpen = !this.onOpen;
      this.isCollapse = false;
    },
    async checkCampaignEligibility() {
      await this.$store.dispatch('promotion/actionGetEligibleCampaigns');
    },
    slideBarCallBack(width) {
      this.sliderBarWidth = width;
    },
    headerCallBack(width) {
      this.headerWidth = width;
    }
  }
};
</script>

<style lang="scss">
@import '@/assets/css/app.scss';
.v-leave {
  opacity: 1;
}
.v-leave-active {
  transition: opacity 0.5s;
}
.v-leave-to {
  opacity: 0;
}
.v-enter {
  opacity: 0;
}
.v-enter-active {
  transition: opacity 0.2s;
}
.v-enter-to {
  opacity: 1;
}
</style>
