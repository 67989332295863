<template>
  <header :class="{ mobile: isMobile }">
    <ul class="clearfix">
      <li class="fl" :style="{ width: `${headerLeftContainerWidth}px`, display: 'flex', alignItems: 'center' }">
        <!-- 登入後 顯示 -->
        <img
          src="@/assets/images/header/scrollClose.png"
          class="header-btn"
          @click="openMenu"
          v-show="isShowCloseMenu"
          alt="close menu"
          data-testid="closeMenu"
        />
        <img
          src="@/assets/images/header/scroll.png"
          class="header-btn"
          @click="openMenu"
          v-show="isShowOpenMenu"
          alt="open menu"
          data-testid="openMenu"
        />

        <img
          v-show="isRestrictSidebar"
          src="@/assets/images/pug/logo.png"
          class="logo-image"
          alt="logo"
          data-testid="logo"
        />
        <!-- 通知 -->
        <HeaderNotification
          :headerWidth="headerWidth"
          :sliderBarWidth="sliderBarWidth"
          :isRestrictSidebar="isInhibitSidebar"
          :isMobile="isMobile"
        ></HeaderNotification>
      </li>

      <li
        class="fr"
        v-resizeObserver="{
          openResize: true,
          handler: headerRightCallback
        }"
      >
        <div class="time-counter">
          <TimerCounter />
        </div>
        <el-dropdown
          class="flag"
          trigger="click"
          :hide-on-click="false"
          @command="langCommand"
          @visible-change="addLangStyle"
          data-testid="dropdownFlag"
        >
          <div class="lang-select">
            <img class="area-img" :src="language.icon" alt />
            <img v-if="openLangImg" class="down" src="@/assets/images/login/lang_down_open.png" alt="" />
            <img v-else src="@/assets/images/login/lang_down.png" alt="" />
          </div>
          <el-dropdown-menu slot="dropdown" class="lang-dropdown-box" placement="right-end">
            <el-dropdown-item
              v-for="item in languages"
              :key="item.value"
              :command="item"
              :class="{ active: lang == item.value }"
              :data-testid="item.value"
            >
              <img :src="item.icon" alt="" />
              <span>{{ item.label }}</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown trigger="click" @command="handleCommand" @visible-change="addInfoStyle">
          <div class="login-inner">
            <img src="@/assets/images/header/member_logo.png" alt class="member-logo" />
            <div class="user-name">
              <span class="name">{{ userName }}</span>
            </div>
            <img v-if="openInfoImg" src="@/assets/images/login/lang_down_open.png" alt="" />
            <img v-else src="@/assets/images/login/lang_down.png" alt="" />
          </div>
          <el-dropdown-menu slot="dropdown" class="login_dropdown_box">
            <el-dropdown-item command="myProfile" class="myProfile" data-testid="myProfile">
              <div class="icon"></div>
              <span>{{ $t('menu.myProfile') }}</span>
            </el-dropdown-item>

            <el-dropdown-item command="securityManagement" class="securityManagement" data-testid="securityManagement">
              <div class="icon"></div>
              <span>{{ $t('menu.securityManagement') }}</span>
            </el-dropdown-item>

            <el-dropdown-item command="goToIbPortal" class="login_back" v-show="isIb" data-testid="goToIbPortal">
              <div class="icon"></div>
              <span>{{ $t('common.button.goToIB') }}</span>
            </el-dropdown-item>
            <el-dropdown-item command="logout" class="logout" data-testid="logout">
              <div class="icon"></div>
              <span>{{ $t('common.button.logout') }}</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </li>
    </ul>
  </header>
</template>

<script>
import helper from '@/util/signinHelper';
import headerMixins from '@/mixins/component/template/header';
import HeaderNotification from '@/components/notification/HeaderNotification';

import TimerCounter from '@/components/common/TimerCounter';

export default {
  name: 'vHeader',
  components: { HeaderNotification, TimerCounter },
  mixins: [headerMixins],
  props: {
    matches: Boolean,
    onOpen: Boolean,
    isCollapse: Boolean,
    sliderBarWidth: Number,
    headerWidth: Number,
    isRestrictSidebar: Boolean
  },
  data() {
    return {
      isIb: false,
      openLangImg: false,
      openInfoImg: false,
      headerRightWidth: 0
    };
  },
  computed: {
    userName() {
      return this.$store.state.common.userName;
    },
    loginStatus() {
      return this.$store.state.common.loginStatus;
    },
    userType() {
      return this.$store.state.common.userType;
    },
    isInhibitSidebar() {
      return this.isRestrictSidebar;
    },
    isShowOpenMenu() {
      if (this.isRestrictSidebar) return false;
      return this.isCollapse || this.matches;
    },
    isShowCloseMenu() {
      if (this.isRestrictSidebar) return false;
      return this.onOpen && !this.isCollapse;
    },
    isMobile() {
      return this.headerWidth <= 924;
    },
    headerLeftContainerWidth() {
      const length = this.headerWidth - this.headerRightWidth - 104;
      return length <= 33 ? 33 : length;
    }
  },
  mounted() {
    this.getUserInfo();
  },
  methods: {
    addInfoStyle(bool) {
      this.openInfoImg = bool;
    },
    openMenu() {
      this.$emit('openMenu');
    },
    logout() {
      helper.signOut(null,'manually');
    },
    handleCommand(command) {
      console.log(command);
      if (command == 'logout') this.logout();
      else if (command == 'myProfile') this.$router.push('/myProfile');
      else if (command == 'securityManagement') this.$router.push('/securityManagement');
      else if (command == 'goToIbPortal' && this.isIb) this.$redirect.redirectToIbPortal();
    },
    langCommand(command) {
      this.language = command;
    },
    getUserInfo() {
      this.isIb = this.userType === 5 || this.userType === 16 ? true : false;
    },
    headerRightCallback(width) {
      this.headerRightWidth = width;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/layout/header.scss';

.logo-image {
  width: 120px;
}
</style>
