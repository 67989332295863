export default {
  computed: {
    id3Pass() {
      return this.$store.state.common.id3Pass
    },
    countryCode() {
      return this.$store.state.common.countryCode
    },
    openAccountEnabled() {
      return this.$store.state.common.openAccountEnabled
    },
    openAdditionAccountEnabled() {
      return (
        this.openAccountEnabled &&
        this.id3Pass &&
        !this.$config.restrictOpenAdditionAccountCountries(this.regulator).includes(parseInt(this.countryCode))
      )
    },
  },
}
