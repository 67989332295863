<template>
  <div class="common_dialog_wrapper" ref="home_dialog">
    <NDialog :visible="visible" :append-to-body="true" :before-close="close" data-testid="notificationDialog">
      <h4 slot="title">
        {{ notificationsItem.subject }}
      </h4>
      <div class="dialog_body">
        <div v-html="notificationsItem.document"></div>
      </div>
      <div slot="footer" v-if="notifications && notifications.length > 1">
        <div class="d-flex align-items-center justify-content-center">
          <el-pagination
            class="pb-0"
            :pager-count="5"
            layout="prev, pager, next"
            @current-change="handleCurrentChange"
            :total="total"
            :page-size="1"
            :current-page="currentPage"
          >
          </el-pagination>
        </div>
      </div>
    </NDialog>
  </div>
</template>

<script>
import NDialog from '@/components/NDialog.vue';
import { HTMLdecode } from '@/util/utils.js';
export default {
  name: 'notice',
  components: {
    NDialog
  },
  props: {
    notificationVisible: Boolean,
    notifications: Array
  },
  data() {
    return {
      visible: false,
      currentPage: 1
    };
  },
  watch: {
    notificationVisible(bool) {
      this.visible = bool;
    },
    visible(bool) {
      this.$emit('update:notificationVisible', bool);
    }
  },
  methods: {
    close() {
      this.visible = false;
      this.currentPage = 1;
      this.$store.commit('common/setAutoNotifyStatus', false);
      this.$store.commit('common/setShowHomePopup', true);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
    },
    handleChangePage(val) {
      switch (val) {
        case 'abstract':
          this.currentPage > 1 && this.currentPage--;
          break;
        case 'add':
          this.currentPage < this.notifications.length && this.currentPage++;
          break;
        default:
          break;
      }
    }
  },
  computed: {
    lang() {
      return this.$store.state.common.lang;
    },
    notificationsItem() {
      const currentNotification = this.notifications[this.currentPage - 1];
      let currentLangNotiDoc = currentNotification.notificationDocuments.find(doc => doc.language_code === this.lang);
      if (!currentLangNotiDoc || currentLangNotiDoc.is_del === 1) {
        currentLangNotiDoc = currentNotification.notificationDocuments.find(doc => doc.language_code === 'en_US');
      }
      if (!currentLangNotiDoc) {
        return {
          subject: '',
          document: ''
        };
      }
      return {
        subject: HTMLdecode(currentLangNotiDoc.subject),
        document: currentLangNotiDoc.document
      };
    },
    total() {
      return this.notifications.length;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/notification/notice.scss';
</style>
