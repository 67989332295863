var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-container',{key:_vm.reRender,staticClass:"wrapper"},[_c('el-aside',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isRestrictSidebar),expression:"!isRestrictSidebar"},{name:"resizeObserver",rawName:"v-resizeObserver",value:({
      openResize: true,
      handler: _vm.slideBarCallBack
    }),expression:"{\n      openResize: true,\n      handler: slideBarCallBack\n    }"}],attrs:{"width":!_vm.matches ? _vm.center : ''}},[_c('slideBar',{attrs:{"isCollapse":_vm.isCollapse,"matches":_vm.matches,"onOpen":_vm.onOpen},on:{"update:isCollapse":function($event){_vm.isCollapse=$event},"update:is-collapse":function($event){_vm.isCollapse=$event},"update:onOpen":function($event){_vm.onOpen=$event},"update:on-open":function($event){_vm.onOpen=$event},"matchesClose":_vm.matchesClose}})],1),_c('el-container',{directives:[{name:"resizeObserver",rawName:"v-resizeObserver",value:({
      openResize: true,
      handler: _vm.headerCallBack
    }),expression:"{\n      openResize: true,\n      handler: headerCallBack\n    }"}],staticClass:"is-vertical"},[_c('vHeader',{attrs:{"matches":_vm.matches,"onOpen":_vm.onOpen,"isCollapse":_vm.isCollapse,"sliderBarWidth":_vm.sliderBarWidth,"headerWidth":_vm.headerWidth,"isRestrictSidebar":_vm.isRestrictSidebar},on:{"update:onOpen":function($event){_vm.onOpen=$event},"update:on-open":function($event){_vm.onOpen=$event},"update:isCollapse":function($event){_vm.isCollapse=$event},"update:is-collapse":function($event){_vm.isCollapse=$event},"openMenu":_vm.openMenu}}),_c('el-main',{attrs:{"id":"elMain"}},[(_vm.showBanner)?_c('Banner'):_vm._e(),_c('transition',[_c('router-view')],1),_c('SessionDialog')],1)],1),(_vm.showPopupBanner)?_c('CarouselBanner'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }