<template>
  <div id="menu">
    <div class="menu-shadow" v-show="matches && onOpen" @click="matchesClose" data-testid="menu_shadow"></div>
    <div class="menu-wrapper" :style="menu_wrapperStyle">
      <div class="menu_logo" v-if="(!isCollapse || matches) && onOpen">
        <router-link to="/home" class="logo">
          <img alt class="logo-img" />
        </router-link>
        <img
          v-if="matches"
          src="@/assets/images/header/scrollClose.png"
          alt
          @click="matchesClose"
          class="menu_back"
          data-testid="menu_back"
        />
      </div>
      <div class="menu_logo_small" v-else>
        <router-link to="/home" class="logo">
          <img alt class="logo-img" />
        </router-link>
      </div>
      <el-scrollbar style="height: 100%">
        <el-collapse-transition>
          <el-menu
            class="el-menu-vertical-demo"
            background-color="#ffffff"
            text-color="rgb(0, 76, 178)"
            active-text-color="rgb(0, 76, 178)"
            :unique-opened="true"
            :default-active="$route.path"
            :collapse="isCollapse && !matches"
          >
            <div v-for="(menu, index) in filteredMenus" v-bind:key="index">
              <el-menu-item
                :index="checkHomeUrl(menu.path)"
                v-if="checkRegister(menu)"
                @click="clickMenu(menu)"
                :data-testid="menu.name"
              >
                <div class="icon" :style="{ backgroundImage: `url(${menu.icon})` }"></div>
                <span
                  slot="title"
                  class="menu-item"
                  :class="[menu.name === 'menu.propTrade' ? lang : '']"
                  @click="menuClick(menu.path)"
                  >{{ $t(menu.name) }}</span
                >
                <div class="line"></div>
                <div class="dot" v-if="menu.path.includes('register')"></div>
                <div class="tag no-child" v-if="menu.tag">{{ $t(menu.tag) }}</div>
              </el-menu-item>
              <el-submenu :index="menu.path" v-if="menu.children" :data-testid="menu.name">
                <template slot="title">
                  <div class="icon" :style="{ backgroundImage: `url(${menu.icon})` }"></div>
                  <span slot="title" class="menu-item" :class="[menu.name === 'menu.propTrade' ? lang : '']">{{
                    $t(menu.name)
                  }}</span>
                  <div class="line"></div>
                  <div class="tag" v-if="menu.tag">{{ $t(menu.tag) }}</div>
                </template>

                <template v-for="(children, index) in menu.children">
                  <el-menu-item
                    v-bind:key="index"
                    :index="children.path"
                    v-if="isOptional(children)"
                    @click="clickMenu(children)"
                    :data-testid="children.name"
                  >
                    <div class="icon" :style="{ backgroundImage: `url(${children.icon})` }"></div>
                    {{ showTitleLanguage(children) }}
                    <div class="line"></div>
                    <div class="tag no-child" v-if="children.tag">{{ $t(children.tag) }}</div>
                  </el-menu-item>
                </template>
              </el-submenu>
            </div>
          </el-menu>
        </el-collapse-transition>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import openAccountMixin from '@/mixins/openAccount';
import blackList from '@/mixins/blackList';
import { statusConfig } from '@/constants/status';
import {
  PROMOTION_TYPE_PATH_MAP,
  PROMOTION_ID_PATH_MAP,
  PROMOTION_WITH_CHILDS,
  PROMOTION_MENU_ICON
} from '@/constants/promotion';
import { mapState } from 'vuex';

function filterPromotion(menu, campaignEligibilityPath) {
  const propTradingState = this.$store.state.propTrading;

  if (!propTradingState.eligible) {
    menu = menu.filter(menu => menu.name !== 'menu.propTrade');
  } else if (!propTradingState.session && !propTradingState.account) {
    menu = menu.map(item => {
      if (item.name === 'menu.propTrade') {
        ['menu.propTradeDashboard', 'menu.propTradeAchievement'].forEach(name => {
          const childIndex = item.children.findIndex(submenu => submenu.name === name);
          if (~childIndex) item.children.splice(childIndex, 1);
        });
      }
      return item;
    });
  }

  for (const [index, item] of menu.entries()) {
    if (item.name === 'menu.promotion') {
      menu = [...menu];
      let usedMenu = [];
      campaignEligibilityPath.forEach(item => {
        let found = menu[index].children.find(child => child.path == item.path);
        if (PROMOTION_WITH_CHILDS.includes(item.campaignType)) {
          let currentMenu = {
            clientPortalSettings: item.clientPortalSettings ? item.clientPortalSettings : [],
            name: item.name,
            path: item.path,
            icon: PROMOTION_MENU_ICON[item.campaignType]
          };
          usedMenu.push(currentMenu);
        } else if (found && Object.keys(found).length > 0) {
          usedMenu.push(found);
        }
      });
      menu[index].children = usedMenu;
      return menu;
    }
  }
  return menu;
}

export default {
  name: 'vMenu',
  data() {
    return { bodyDirection: null };
  },
  mixins: [openAccountMixin, blackList],
  props: {
    isCollapse: Boolean,
    matches: Boolean,
    onOpen: Boolean
  },
  mounted() {
    this.$nextTick(() => {
      this.bodyDirection = document.body.dir;
    });
  },
  methods: {
    matchesClose() {
      this.$emit('matchesClose');
    },
    checkMatchesClose() {
      const vm = this;
      this.$nextTick(function () {
        if (vm.matches) vm.matchesClose();
      });
    },
    clickMenu(children) {
      if (!children.hasOwnProperty('checkBlacklist')) {
        this.checkMatchesClose();
        //https://github.com/vuejs/vue-router/issues/2872
        this.$router.push({ path: children.path }).catch(err => {});
      } else {
        if (children.path === '/depositFunds') this.getDepositBlacklist();
        else if (children.path === '/transferFunds') this.goToTransferFunds();
        else this.getWithdrawalBlacklist();
      }
    },
    isOptional(children) {
      if (!children.hasOwnProperty('optional')) return children;
      else {
        // openAccountMixin
        if (this.openAdditionAccountEnabled) return children;
      }
    },
    checkRegister(menu) {
      return (
        (menu.path != '/register' ||
          this.poiAuditStatus !== statusConfig.completed ||
          this.poaAuditStatus !== statusConfig.completed) &&
        !menu.children
      );
    },
    checkHomeUrl(path) {
      return path == '/home' && this.registerStep == 1 && this.isDemo ? '/homeDemo' : path;
    },
    menuClick(url) {
      if (this.isCollapse) this.$router.push({ path: url });
    },
    filterPromotionTypePath() {
      const mapPathArray = this.campaignEligibility.map(function (campaign) {
        return {
          ...campaign,
          path: Object.keys(PROMOTION_ID_PATH_MAP)
            .map(item => parseInt(item))
            .includes(campaign.id)
            ? PROMOTION_ID_PATH_MAP[campaign.id]
            : PROMOTION_WITH_CHILDS.includes(campaign.campaignType)
            ? `${PROMOTION_TYPE_PATH_MAP[campaign.campaignType]}${campaign.id}`
            : PROMOTION_TYPE_PATH_MAP[campaign.campaignType]
        };
      });

      return mapPathArray;
    },
    showTitleLanguage(item) {
      let resTitle = this.$t(item.name).toUpperCase();

      const menuSettings = item?.clientPortalSettings;
      if (!menuSettings || menuSettings.length < 1) return resTitle;
      let targetLang =
        menuSettings?.length && menuSettings.map(item => item.languageCode).includes(this.lang) ? this.lang : 'en_US';
      return item.clientPortalSettings.find(item => item.languageCode === targetLang)?.title;
    }
  },
  computed: {
    ...mapState('register', ['poiAuditStatus', 'poaAuditStatus']),
    ...mapState('common', ['registerStep', 'isDemo', 'lang']),
    ...mapState('promotion', {
      campaignEligibility: 'eligibleCampaigns'
    }),
    conditionMenus() {
      let rowMenu = this.$config.getMenus(this.regulator);

      // prop trading limitation
      // rowMenu = rowMenu.map(item => {
      //   if (item.name === 'menu.propTrade') {
      //     const childIndex = item.children.findIndex(submenu => submenu.name === 'menu.propTradeDashboard');
      //     item.children.splice(childIndex, 1);
      //   }
      //   return item;
      // });

      return rowMenu;
    },
    filteredMenus() {
      const noCampaignEligibility = () => this.campaignEligibility.length === 0;

      if (noCampaignEligibility()) {
        return this.conditionMenus.filter(menu => menu.name !== 'menu.promotion');
      } else {
        return filterPromotion.bind(this)(this.conditionMenus, this.filterPromotionTypePath());
      }
    },
    menu_wrapperStyle() {
      const menuWidth = { width: this.matches == false && this.isCollapse ? '64px' : '260px' };
      const direction = this.bodyDirection === 'rtl' ? { right: this.menuPosition } : { left: this.menuPosition };
      return { ...direction, ...menuWidth };
    },
    menuPosition() {
      return this.matches && !this.onOpen ? '-260px' : '0px';
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/layout/menu.scss';
</style>
